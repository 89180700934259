import React from 'react'
import { Link, navigate } from 'gatsby'
import Button from '../components/Button'
import Spacer from '../components/Spacer'
import { Input } from '../components/Inputs'
import { bs } from '../shevy'
import MainInner from '../components/MainInner'
import { useAuthContext } from '../contexts/AuthContext'
import Seo from '../components/Seo'
import PasswordInput from '../components/PasswordInput'

export default function Login() {
  const [authState, { login }] = useAuthContext()
  const [state, setState] = React.useState('IDLE')
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [errorMessage, setErrorMessage] = React.useState(null)

  const handleEmailChange = React.useCallback(e => {
    setState('IDLE')
    setErrorMessage(null)
    setEmail(e.target.value)
  }, [])

  const handlePasswordChange = React.useCallback(e => {
    setState('IDLE')
    setErrorMessage(null)
    setPassword(e.target.value)
  }, [])

  const handleSubmit = React.useCallback(
    async e => {
      e.preventDefault()

      setState('PENDING')

      try {
        const { error } = await login(email, password)

        if (error) {
          setErrorMessage(error.message)
          setState('FAILURE')
          return
        }

        setState('SUCCESS')
        navigate('/learn')
      } catch (error) {
        setErrorMessage(error.message)
        setState('FAILURE')
      }
    },
    [email, login, password]
  )

  React.useEffect(() => {
    if (authState === 'AUTHENTICATED') {
      navigate('/learn')
    }
  }, [authState])

  return (
    <>
      <Seo title="Login" />
      <MainInner>
        <h2>Log in</h2>
        <p>
          Log in to your account to purchase a course or watch the ones you
          already&nbsp;own.
        </p>
        <div css={{ backgroundColor: 'var(--colors-offset)', padding: bs() }}>
          {state === 'FAILURE' && (
            <div>
              <p>Error: {errorMessage}</p>
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <Spacer bottom={0.5}>
              <Input
                label="Email"
                onChange={handleEmailChange}
                required
                value={email}
              />
            </Spacer>
            <Spacer bottom={1}>
              <PasswordInput
                label="Password"
                onChange={handlePasswordChange}
                required
                value={password}
              />
            </Spacer>

            <Spacer bottom={1}>
              <Link to="/forgot-password">Forgot password?</Link>
            </Spacer>

            <Button
              disabled={state === 'PENDING'}
              onClick={handleSubmit}
              type="submit"
            >
              Submit
            </Button>
          </form>
          {state === 'SUCCESS' && <Spacer top={1}>Success!</Spacer>}
        </div>
      </MainInner>
    </>
  )
}
